@import "_variables";
html {
    overflow: hidden;
    margin: 0px;
}

body {
    background: white;
    margin: 0px;
}

@media only screen and (max-width: 6px) and (min-width: 4px) {
    video {
        width: 500px;
    }
    overlay {
        width: 500px;
    }
}

#master {
    width: 100%;
    //border: 1px solid green;
    position: relative;
    height: 100%;
    background: #000;
}

video {
    width: 100%;
    max-height: 100vh;
}

#overlay {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 10vh;
    //    border: 2px solid red;
}

.video-js.vjs-time-control {
    display: block;
}

#intLoadingCont {
    position: absolute;
    width: 100%;
    padding: 5px 20px;
    font-size: 18px;
    background-color: white;
    z-index: 10;
    opacity: 0.7;
}

.svgButton {
    position: absolute;
    border: none;
    z-index: 20;
    width: 25%;
}

.svgPanel {
    position: absolute;
    border: none;
    z-index: 2000;
}

#btn_topic_TreatmentSelection {
    //top:55%;
    width: 98%;
    transform: scale(0);
}

// #ButtonGroup {
//     position: absolute;
//     width: 90%;
//     left: 2%;
//     top: -100%;
//     padding: 0;
//     /*border:1px solid red;*/
//     font-size: 100%;
// }
.svgPanelButton {
    display: block;
    background: none;
    padding: none;
    outline: none;
    border: 1px solid red;
    margin: 0px;
}

#btn_slides_img {
    color: black;
}

.svgPanelButton:active {
    color: black;
    outline: none;
    border: none;
}

.svgPanelButton:focus {
    color: black;
    border: none;
    outline: none;
}

#btn_slides_img {
    fill: $color-brand-background;
    stroke: $color-brand-background;
    transform: scale(3);
}

#customoverlay {
    position: relative;
    width: 100vw;
    //getting the height of the overlay based on the height of the video
    height: calc(100vw * 720 / 1280);
    max-height: 100vh;
    //needed to let the player register tap event to stop the video
    pointer-events: none;
    &>* {
        pointer-events: all;
    }
}

#master {
    &.fullscreened {
        #customoverlay {
            top: calc(50vh - 50vw * 720 / 1280);
        }
        #ButtonGroup {
            position: fixed;
        }
        #menu_topics {
            position: fixed;
            top: 40px;
        }
        #menu_polls {
            position: fixed;
            top: 40px;
        }
        #svg_topic_SwitchingToABiosimilar {
            position: fixed;
        }
        .vjs-fullscreen-control {
            .vjs-icon-placeholder {
                &::before {
                    content: "\f109";
                }
            }
        }
        #customoverlay {
            .svgPanel {
                position: fixed;
            }
        }
    }
}

.smalltexts {
    padding: 0;
    font-size: 13px;
    text-align: left;
}

#introVidContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 500;
    display: flex;
    justify-content: center;
    background: rgba(51, 51, 51, 0.5);
    align-items: center;
    .introVidInnerContainer {
        border-radius: 5px;
        background: #adcb8f;
        width: 50%;
        padding: 15px;
        color: white;
        flex-direction: column;
        display: flex;
        min-height: 250px;
        .introVid {
            flex: 1;
            background: black;
            margin: 5px 0px;
            min-height: 250px;
        }
        .introVidSkipCont {
            align-self: flex-end;
            #introVidSkip {
                color: white;
                padding: 0;
            }
        }
    }
}

#myPlayerID {
    position: absolute;
    height: 100%;
}

#tpm-plugin-kh5kl3zu- {
    position: static;
}

.p3sdk-container {
    width: 100% !important;
    display: none;
}

.p3sdk-interactive-transcript-component {
    height: 100% !important;
}

.p3sdk-interactive-transcript {
    height: 100% !important;
}

.p3sdk-interactive-transcript-content {
    height: calc(100% - 49px) !important;
}

.p3sdk-target {
    // position:absolute;
    // top:100%;
    // // left:10%;
    // // height:80%;
    //  width:90%;
    // z-index: 2000;
    width: 100%;
}

.vjs-big-play-button,
.vjs-control-bar {
    z-index: 200;
}

.cont {
    width: 100%;
    min-height: 150px;
    display: flex;
    .CoreVote {
        border-right: 1px solid rgba(51, 51, 51, 0.5);
    }
    .OnlineVote {
        border-left: 1px solid rgba(51, 51, 51, 0.5);
    }
    .CoreVote,
    .OnlineVote {
        position: relative;
        flex: 1;
        padding: 0px 5px;
        display: flex;
        flex-direction: column;
        .title {
            text-align: center;
            padding: 5px;
            font-size: 16px;
        }
        .options {
            display: flex;
            flex: 1;
            .optionNames {
                justify-content: space-between;
                display: flex;
                position: relative;
                flex-direction: column;
                .optionName {
                    margin: 5px;
                    right: 0;
                    text-align: end;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-end;
                    flex: 1;
                }
            }
            .optionVotes {
                flex: 1;
                justify-content: space-between;
                display: flex;
                position: relative;
                flex-direction: column;
                .optionVote {
                    margin: 5px 0px;
                    left: 0;
                    min-height: 20px;
                    background: rgb(63, 121, 238);
                    flex: 1;
                }
            }
        }
    }
}

#tpm-plugin-kh5kl3zu- {
    .vjs-big-play-button {
        display: none !important;
    }
}

.donebtn {
    display: block;
    margin: 5px auto;
}

.loader {
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
    opacity: 0.85;
    text-align: left;
    border: 6px solid rgba(43, 51, 63, 0.4);
    box-sizing: border-box;
    background-clip: padding-box;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    &:after,
    &:before {
        -webkit-animation: vjs-spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite, vjs-spinner-fade 1.1s linear infinite;
        animation: vjs-spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite, vjs-spinner-fade 1.1s linear infinite;
    }
    &:after {
        animation-delay: 0.5s;
    }
}

.loader:before,
.loader:after {
    content: "";
    position: absolute;
    margin: -6px;
    box-sizing: inherit;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    opacity: 1;
    border: inherit;
    border-color: transparent;
    border-top-color: #2b333f;
    ;
}

@keyframes vjs-spinner-spin {
    100% {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes vjs-spinner-spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes vjs-spinner-fade {
    0% {
        border-top-color: #73859f;
    }
    20% {
        border-top-color: #73859f;
    }
    35% {
        border-top-color: white;
    }
    60% {
        border-top-color: #73859f;
    }
    100% {
        border-top-color: #73859f;
    }
}

@-webkit-keyframes vjs-spinner-fade {
    0% {
        border-top-color: #73859f;
    }
    20% {
        border-top-color: #73859f;
    }
    35% {
        border-top-color: white;
    }
    60% {
        border-top-color: #73859f;
    }
    100% {
        border-top-color: #73859f;
    }
}

.svgButton {
    &:hover {
        cursor: pointer;
    }
}

.introVid {
    >div {
        height: 100%;
        min-height: 250px;
        >video {
            position: static !important;
            min-height: 250px;
            display: block;
        }
    }
}

#internetExplorerContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 500;
    display: flex;
    justify-content: center;
    background: rgba(51, 51, 51, 0.5);
    align-items: center;
    display: none;
    .internetExplorerInnerContainer {
        border-radius: 5px;
        background: #adcb8f;
        width: 50%;
        padding: 15px;
        color: white;
        flex-direction: column;
        display: flex;
        .internetExplorerCloseButton {
            width: 10px;
            height: 10px;
            align-self: flex-end;
            margin-bottom: 10px;
            transform-origin: center center;
            &:hover,
            &:focus {
                transform: scale(1.4);
            }
            img {
                width: 100%;
                height: 100%;
                color: white;
            }
        }
    }
}

.svgButton {
     :nth-child(2) {
        width: 14px;
        color: white;
        font-size: 20px;
        height: 14px;
        display: block;
        position: absolute;
        right: 10px;
        top: 10px;
        &:hover {
            transform: scale(1.25);
        }
    }
}

#btn_IsThisForMe {
     :nth-child(2) {
        right: 70px;
        top: 50px;
    }
}

.headingContainer {
    display: flex;
    .headingTextContainer {
        display: flex;
        flex-direction: column;
    }
}